<template>
  <div class="pt-2">
    <div class="d-page-toolbar">
      <d-search-input
        v-model="search"
        :items="searchSuggestions"
      ></d-search-input>
      <v-btn
        v-text="'+ ' + $t('serviceType.createServiceType')"
        class="d-btn-1 ml-auto"
        depressed
        @click="openEditDialog(null)"
      ></v-btn>
    </div>

    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :search="search"
      :page.sync="pageNumber"
      :items-per-page="pageSize"
      hide-default-footer
      class="d-data-table d-data-table--rows-clickable"
      @click:row="openEditDialog"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.initialTreatment`]="{ item }">
        <v-icon v-if="item.initialTreatment" color="d-green">mdi-check</v-icon>
      </template>
      <template v-slot:[`item.duration`]="{ item }">
        {{ item.duration }} {{ $t('serviceType.durationSuffix') }}
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        <d-active-status-icon :value="item.isActive"></d-active-status-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-no-wrap d-visible-on-row-hover">
          <d-btn-icon type="edit" @click="openEditDialog(item)"></d-btn-icon>
          <d-btn-icon type="delete" @click="deleteServiceType_(item)"></d-btn-icon>
        </div>
      </template>
    </v-data-table>

    <v-pagination
      v-model="pageNumber"
      :length="pageCount"
      :total-visible="15"
      class="my-5"
    ></v-pagination>

    <serviceType-edit-dialog
      :dialog.sync="editDialog"
      :serviceType="editDialog_serviceType"
    ></serviceType-edit-dialog>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { serviceTypesPageSize } from '@/constants'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import errorMixin from '@/mixins/error.mixin'

import DSearchInput from '@/components/DSearchInput'
import DBtnIcon from '@/components/DBtnIcon'
import DActiveStatusIcon from '@/components/DActiveStatusIcon'
import ServiceTypeEditDialog from './ServiceTypeEditDialog'


export default {
  components: {
    DSearchInput,
    DBtnIcon,
    DActiveStatusIcon,
    ServiceTypeEditDialog
  },


  mixins: [
    pageMixin,
    snackbarMixin,
    errorMixin
  ],


  data () {
    return {
      search: '',
      pageCount: 0,
      pageNumber: 1,
      pageSize: serviceTypesPageSize,
      editDialog: false,
      editDialog_serviceType: null
    }
  },


  computed: {
    ...mapGetters('serviceType', ['serviceTypes']),

    tableHeaders () {
      return [
        { text: this.$t('serviceType.table.name'), value: 'name', cellClass: 'font-weight-medium' },
        { text: this.$t('serviceType.table.duration'), value: 'duration', filterable: false },
        { text: this.$t('serviceType.table.initialTreatment'), value: 'initialTreatment', filterable: false },
        { text: this.$t('serviceType.table.active'), value: 'isActive', filterable: false },
        { text: '', value: 'actions', align: 'end', width: 1, sortable: false, filterable: false }
      ]
    },

    tableItems () {
      return this.serviceTypes
    },

    searchSuggestions () {
      return this.serviceTypes.map(item => item.name)
    }
  },


  methods: {
    ...mapActions('serviceType', ['loadServiceTypes', 'deleteServiceType']),

    openEditDialog (serviceType) {
      this.editDialog_serviceType = serviceType
      this.editDialog = true
    },

    async deleteServiceType_ (serviceType) {
      try {
        const confirm = await this.$confirm(this.$t('serviceType.deleteServiceTypeConfirm', serviceType), { trueText: this.$t('delete') })

        if (confirm === true) {
          this.showSnackbarProcessing()
          await this.deleteServiceType(serviceType.id)
          this.showSnackbarSuccess(this.$t('serviceType.serviceTypeDeleted'))
        }
      } catch (error) {
        if (this.is405NotAllowedError(error)) {
          this.hideSnackbar()
          await this.$alert(this.$t('serviceType.cannotDeleteWithRelations', serviceType), { title: this.$t('failed') })
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  },


  async created () {
    try {
      this.startPageLoading()
      await this.loadServiceTypes()
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    } finally {
      this.stopPageLoading()
    }
  }
}
</script>
