<template>
  <v-icon
    v-if="value === true"
    color="d-green"
  >
    mdi-bookmark-check
  </v-icon>

  <v-icon
    v-else-if="value === false"
    color="d-red"
  >
    mdi-bookmark-minus
  </v-icon>
</template>


<script>
export default {
  inheritAttrs: false,


  props: {
    value: {
      type: Boolean
    }
  }
}
</script>
