<template>
  <v-combobox
    :search-input.sync="searchInput"
    :items="items"
    :placeholder="$t('components.searchInput.placeholder')"
    :menu-props="{ value: (menu && items && items.length), listIndex: 0 }"
    class="d-search-input"
    :class="{ 'd-search-input--menu-active': menu }"
    :return-object="false"
    prepend-inner-icon="mdi-magnify"
    append-icon=""
    hide-no-data
    hide-details
    solo
    dense
    clearable
    @keyup="onKeyup"
    @change="onChange"
  ></v-combobox>
</template>


<script>
export default {
  inheritAttrs: false,


  props: {
    value: {
      type: String
    },
    items: {
      type: Array
    }
  },


  data () {
    return {
      menu: false,
      oldValue: this.value
    }
  },


  computed: {
    searchInput: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },


  methods: {
    onKeyup (event) {
      if (event.keyCode === 13 /* Enter */) {
        this.menu = false
      } else {
        this.menu = !!this.searchInput
      }
    },

    onChange (val) {
      this.menu = false
      if (val !== this.oldValue) {
        this.$emit('submit', val)
      }
      this.oldValue = val
    }
  }
}
</script>
