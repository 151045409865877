const mixin = {
  props: {
    dialog: Boolean
  },


  watch: {
    dialog: function (value) {
      if (value === true && this.onDialogOpen) {
        this.onDialogOpen()
      }
    }
  },


  methods: {
    closeDialog () {
      this.$emit('update:dialog', false)
    }
  }
}


export default mixin
