<template>
  <v-dialog
    v-if="editedServiceType"
    :value="dialog"
    :persistent="isModelChanged()"
    :max-width="450"
    content-class="d-dialog"
    scrollable
    @keydown.esc="escape(closeDialog)"
    @click:outside="escape(closeDialog)"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ serviceType ? $t('serviceType.editServiceType') : $t('serviceType.createServiceType') }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          tabindex="4"
          @click="escape(closeDialog)"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" @submit.stop.prevent>
          <v-text-field
            v-model.trim="editedServiceType.name"
            :rules="rules.name"
            :label="$t('serviceType.edit.name')"
            tabindex="1"
            clearable
            autofocus
          ></v-text-field>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="editedServiceType.duration"
                :rules="rules.duration"
                :label="$t('serviceType.edit.duration')"
                :suffix="$t('serviceType.durationSuffix')"
                type="number"
                tabindex="1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-checkbox
            v-model="editedServiceType.initialTreatment"
            :label="$t('serviceType.edit.initialTreatment')"
            tabindex="1"
          ></v-checkbox>
          <v-checkbox
            v-model="editedServiceType.isActive"
            :label="$t('serviceType.edit.active')"
            tabindex="1"
          ></v-checkbox>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-text="$t('cancel')"
          color="secondary"
          text
          tabindex="3"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          v-text="$t('save')"
          :disabled="!isModelChanged()"
          color="primary"
          text
          tabindex="2"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { serviceTypeSchema } from '@/constants'

import objectUtil from '@/utils/object.util'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import unsavedChangesMixin from '@/mixins/unsavedChanges.mixin'
import errorMixin from '@/mixins/error.mixin'

import DBtnIcon from '@/components/DBtnIcon'


export default {
  props: {
    serviceType: Object
  },


  components: {
    DBtnIcon
  },


  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    unsavedChangesMixin,
    errorMixin
  ],


  data () {
    return {
      editedServiceType: null,
      defaultServiceType: {
        name: '',
        duration: null,
        initialTreatment: false,
        isActive: true
      },
      existingNames: []
    }
  },


  computed: {
    ...mapGetters('serviceType', ['serviceTypes']),

    rulesToApply () {
      const rule = this.rule

      return {
        name: [
          rule.required(),
          rule.maxLength(serviceTypeSchema.nameMaxLength),
          rule.unique(this.existingNames, this.$t('serviceType.edit.nameExists'))
        ],
        duration: [rule.required()]
      }
    }
  },


  methods: {
    ...mapActions('serviceType', ['createServiceType', 'updateServiceType']),

    onDialogOpen () {
      this.editedServiceType = objectUtil.getObjectCopy(this.serviceType || this.defaultServiceType)

      this.initWatchedModel(this.editedServiceType)
      this.resetValidation()

      this.existingNames = this.serviceTypes.filter(x => x.id !== this.editedServiceType.id).map(x => x.name)
    },

    async submit () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid || !this.isModelChanged()) return

      try {
        this.showSnackbarProcessing()
        const serviceType = objectUtil.getObjectCopy(this.editedServiceType)

        if (serviceType.id) {
          await this.updateServiceType(serviceType)
          this.showSnackbarSuccess(this.$t('serviceType.serviceTypeUpdated'))
        } else {
          await this.createServiceType(serviceType)
          this.showSnackbarSuccess(this.$t('serviceType.serviceTypeCreated'))
        }

        this.closeDialog()
      } catch (error) {
        if (this.is409ConflictError(error)) {
          this.hideSnackbar()
          this.existingNames.push(this.editedServiceType.name)
          this.validate(this.rulesToApply)
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  }
}
</script>
