<template>
  <v-tooltip
    :disabled="!attrs.tooltip"
    bottom
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        :class="btnClass"
        :color="color"
        icon
        @click.stop="$emit('click')"
      >
        <v-icon>{{ attrs.icon }}</v-icon>
      </v-btn>
    </template>
    {{ attrs.tooltip }}
  </v-tooltip>
</template>


<script>
export default {
  inheritAttrs: false,


  props: {
    type: {
      type: String
    },
    tooltip: {
      type: String
    },
    icon: {
      type: String
    },
    color: {
      type: String
    },
    btnClass: {
      type: undefined
    }
  },


  data () {
    return {
      presets: {
        edit: {
          icon: 'mdi-pencil-outline',
          tooltip: this.$t('edit')
        },
        delete: {
          icon: 'mdi-delete-outline',
          tooltip: this.$t('delete')
        },
        close: {
          icon: 'mdi-close',
          tooltip: this.$t('close')
        },
        lock: {
          icon: 'mdi-lock-outline'
        }
      }
    }
  },


  computed: {
    attrs () {
      const defaultAttrs = this.presets[this.type] || {}

      const attrs = {
        tooltip: this.tooltip || defaultAttrs.tooltip,
        icon: this.icon || defaultAttrs.icon
      }

      return attrs
    }
  }
}
</script>
