var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"d-page-toolbar"},[_c('d-search-input',{attrs:{"items":_vm.searchSuggestions},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"d-btn-1 ml-auto",attrs:{"depressed":""},domProps:{"textContent":_vm._s('+ ' + _vm.$t('serviceType.createServiceType'))},on:{"click":function($event){return _vm.openEditDialog(null)}}})],1),_c('v-data-table',{staticClass:"d-data-table d-data-table--rows-clickable",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"search":_vm.search,"page":_vm.pageNumber,"items-per-page":_vm.pageSize,"hide-default-footer":""},on:{"update:page":function($event){_vm.pageNumber=$event},"click:row":_vm.openEditDialog,"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.initialTreatment",fn:function(ref){
var item = ref.item;
return [(item.initialTreatment)?_c('v-icon',{attrs:{"color":"d-green"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration)+" "+_vm._s(_vm.$t('serviceType.durationSuffix'))+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('d-active-status-icon',{attrs:{"value":item.isActive}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap d-visible-on-row-hover"},[_c('d-btn-icon',{attrs:{"type":"edit"},on:{"click":function($event){return _vm.openEditDialog(item)}}}),_c('d-btn-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteServiceType_(item)}}})],1)]}}],null,true)}),_c('v-pagination',{staticClass:"my-5",attrs:{"length":_vm.pageCount,"total-visible":15},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}),_c('serviceType-edit-dialog',{attrs:{"dialog":_vm.editDialog,"serviceType":_vm.editDialog_serviceType},on:{"update:dialog":function($event){_vm.editDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }