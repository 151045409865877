const mixin = {
  methods: {
    hasHttpStatusCode (error, status) {
      return error?.response?.status === status
    },

    is404NotFoundError (error) {
      return this.hasHttpStatusCode(error, 404)
    },

    is405NotAllowedError (error) {
      return this.hasHttpStatusCode(error, 405)
    },

    is409ConflictError (error) {
      return this.hasHttpStatusCode(error, 409)
    }
  }
}


export default mixin
